import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {

  private mobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)


  setMobile(isMobile: boolean){
    this.mobile.next(isMobile)
  }

  getMobile(){
    return this.mobile.getValue()
  }

}